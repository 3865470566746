import React from "react";
import "./EqPdfInvoice.scss";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFViewer,
    Image,
    Font,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { ticksToMilliSeconds } from "../../components/shared/Common";
import { QuestionType } from "../../components/shared/enums";

Font.register({
    family: "Cinzel",
    src: "https://fonts.gstatic.com/s/cinzel/v13/8vII7w4BM0XufO-5dRuZDeRCEAM.woff2",
});

// Styles for the PDF document to match the image
const styles = StyleSheet.create({
    page: {
        padding: 40,
        fontSize: 12,
        fontFamily: "Helvetica",
        color: "#333",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 30,
    },
    companyDetails: {
        textAlign: "left",
    },
    companyLogo: {
        textAlign: "right",
        maxWidth: "auto",
        height: 50,
        justifyContent: "center",
        flexDirection: "row",

        alignItems: "center",
        display: "flex",
        padding: 5,
        border: "1px solid #FF8838",
    },

    logo: {
        // width: 40, // Adjust as needed
        height: 40,
        marginRight: 10,
    },

    logoText: {
        fontSize: 24,
        // fontFamily: 'Cinzel',
    },

    title: {
        fontSize: 24,
        marginBottom: 4,
        color: "#FF8838",
        textAlign: "right",
        fontWeight: "bold",
    },
    section: {
        marginBottom: 20,
    },
    infoContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 20,
        width: "100%",
    },

    customerInfo: {
        display: "flex",
        marginTop: 20,
        fontSize: 10,
    },
    customerContentHeading: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 1,
        fontSize: 10,
        color: "#FF8838",
    },
    customerName: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 1,
        marginBottom: 6,
        fontWeight: "bold",
        fontSize: 14,
    },
    customerContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 1,
        fontSize: 10,
    },

    invoiceInfo: {
        display: "flex",
        // flexDirection: 'row',
        justifyContent: "flex-end",
        marginTop: 20,
        fontSize: 10,
    },
    invoiceContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 4,
    },
    invoiceDecHeading: {
        fontSize: 12,
        fontWeight: "bold",
        color: "#FF8838",
        textAlign: "right",
    },
    invoiceDecText: {
        fontSize: 12,
    },

    questionSection: {
        width: "100%",
        border: "1px solid #FF8838",
        padding: 6,
        marginBottom: 5,
    },
    questionContainer: {
        border: "1px solid #e0e0e0",
        padding: 8,
        margin: "4 0",
    },
    question: {
        fontSize: 12,
        fontWeight: "bold",
        color: "black",
        marginBottom: 7,
    },
    answer: {
        fontSize: 12,
        color: "#474e51",
    },

    table: {
        width: "100%",
        // borderTop: '2px solid #4CAF50',
        borderBottom: "2px solid #FF8838",
    },
    tableHeader: {
        display: "flex",
        flexDirection: "row",
        backgroundColor: "#FF8838",
        color: "white",
        padding: 8,
        fontSize: 10,
    },
    tableRow: {
        display: "flex",
        flexDirection: "row",
        padding: 8,
        borderBottom: "1px solid #e0e0e0",
    },
    tableCell: {
        flex: 1,
        textAlign: "right",
    },
    descriptionCell: {
        flex: 4,
        textAlign: "left",
        paddingLeft: 5,
    },
    totalSection: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 20,
        fontSize: 10,
    },
    subTotalRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        // marginBottom: 5,
        padding: 8,
        borderBottom: "1px solid #e0e0e0",
    },
    totalRow: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        // marginBottom: 5,
        padding: 8,
        backgroundColor: "#ffe0c7",
        borderBottom: "2px solid #FF8838",
        borderTop: "2px solid #FF8838",
    },
    subAmount: {
        fontSize: 12,
        fontWeight: "bold",
    },
    totalAmount: {
        color: "#FF8838",
        fontSize: 12,
        fontWeight: "bold",
    },
    notes: {
        marginTop: 40,
        fontSize: 10,
        color: "#666",
    },
});

export interface IEqPdfInvoice {
    bookingDataList: any[];
}
const EqPdfInvoice = ({ bookingDataList }: IEqPdfInvoice) => {
    const firstBooking = bookingDataList.length > 0 ? bookingDataList[0] : null;
    // const eventTitle = firstBooking.eventClass.alternative.publicEvent?.title;
    // const OwnerUser = firstBooking.eventClass.alternative.publicEvent?.user;
    const Attendee = firstBooking.user;
    const attendeeLocation = firstBooking?.user?.locations[0]?.location;
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const { t } = useTranslation();
    const logoSrc = "https://equinet.no/images/Logo.svg";
    const logoSrc1 = "logoEquinet.png";

    return (
        // <PDFViewer style={{ width:"100%", maxWidth: '80vw', height:"80vh"}}>
        <Document>
            <Page size="A4" style={styles.page}>
                {/* Header Section */}
                <View style={styles.header}>
                    <View style={styles.companyDetails}>
                        <Text style={styles.customerName}>Equinet AS</Text>
                        <Text style={styles.customerContent}>Gamle Kongsvingerveg 4,</Text>
                        <Text style={styles.customerContent}>2040 Kløfta Norway.</Text>
                    </View>
                    <View style={styles.companyLogo}>
                        <Image src={logoSrc1} style={styles.logo} />
                        {/* <Text style={styles.logoText}>Equinet</Text> */}
                    </View>
                </View>

                {/* Title */}
                <Text style={styles.title}>{t("receipt")}</Text>

                {/* Info Section (Billed to and Receipt details) */}
                <View style={styles.infoContainer}>
                    <View style={styles.customerInfo}>
                        <Text style={styles.customerContentHeading}>{t("billedTo")}</Text>
                        <Text style={styles.customerName}>
                            {(Attendee?.firstName ? Attendee?.firstName : "") +
                                " " +
                                (Attendee?.lastName ? Attendee?.lastName : "")}
                        </Text>
                        {/* <Text style={styles.customerName}>{Attendee?.firstName?Attendee?.firstName:""  +" " + Attendee?.lastName?Attendee?.lastName:"" }</Text> */}
                        <Text style={styles.customerContent}>{attendeeLocation?.street}</Text>
                        <Text style={styles.customerContent}>{attendeeLocation?.zipCode}</Text>
                    </View>
                    <View style={styles.invoiceInfo}>
                        <View style={styles.invoiceContent}>
                            <Text style={styles.invoiceDecHeading}>{t("receipt")} #:</Text>
                            <Text style={styles.invoiceDecText}>
                                {firstBooking.eventClass.alternative.publicEvent.id}
                            </Text>
                        </View>
                        <View style={styles.invoiceContent}>
                            <Text style={styles.invoiceDecHeading}>
                                {t("receipt")} {t("date")}:
                            </Text>
                            <Text style={styles.invoiceDecText}>
                                {new Date(
                                    ticksToMilliSeconds(firstBooking?.createdDate)
                                ).toLocaleDateString(t("dateLang"), options as any)}
                            </Text>
                        </View>
                        {/* <Text>Receipt #: 0000457</Text>
                            <Text>Receipt date: 11-04-2023</Text> */}
                    </View>
                </View>

                {/* Question/Answer */}
                {bookingDataList.map((order, idx) => (
                    <View key={idx}>
                        {order.answers && order.answers.length > 0 && (
                            <View style={styles.questionSection}>
                                <Text style={styles.question}>{order.eventClass.title}</Text>
                                <br />
                                <Text>{t("questionsAndAnswers")}</Text>
                                <br />

                                <View>
                                    {order.answers.map((answer: any, qIdx: number) => (
                                        <View key={qIdx} style={styles.questionContainer}>
                                            <Text style={styles.question}>
                                                {answer.question.questionText}
                                            </Text>
                                            <br />
                                            {answer.question.questionType === QuestionType.Text ? (
                                                <Text style={styles.answer}>
                                                    {answer.answerText}
                                                </Text>
                                            ) : (
                                                answer.selectedOptions.map(
                                                    (option: any, oIdx: number) => (
                                                        <Text style={styles.answer} key={oIdx}>
                                                            {option.option.optionText}
                                                        </Text>
                                                    )
                                                )
                                            )}
                                        </View>
                                    ))}
                                </View>
                            </View>
                        )}
                    </View>
                ))}
                {/* <View style={styles.questionSection}>
                        {questions.map((question, index) => (
                            <View style={styles.questionContainer}>
                                <Text style={styles.question}>{question.question}</Text>
                                <Text style={styles.answer}>{question.answer}</Text>

                            </View>
                        ))}
                    </View> */}

                {/* Table Section */}
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        {/* <Text style={styles.tableCell}>QTY</Text> */}
                        <Text style={styles.descriptionCell}>{t("items")}</Text>
                        <Text style={styles.tableCell}>{t("price")}</Text>
                        <Text style={styles.tableCell}>{t("vat")}</Text>
                    </View>

                    {/* Table Rows */}
                    {bookingDataList.map((order, index) => (
                        <View key={index} style={styles.tableRow}>
                            <Text style={styles.descriptionCell}>{order.eventClass.title}</Text>
                            <Text style={styles.tableCell}>
                                {order.eventClass.price.toFixed(2)}
                            </Text>
                            <Text style={styles.tableCell}>{order.eventClass.vat.toFixed(2)}</Text>
                        </View>
                    ))}
                </View>

                {/* Total Section */}
                <View style={styles.totalSection}>
                    <View style={{ width: "50%" }}>
                        {/* <View style={styles.subTotalRow}>
                                <Text style={styles.subAmount}>Subtotal:</Text>
                                <Text style={styles.subAmount}>$230.00</Text>
                            </View>
                            <View style={styles.subTotalRow}>
                                <Text style={styles.subAmount}>Sales Tax (5%):</Text>
                                <Text style={styles.subAmount}>$11.50</Text>
                            </View> */}
                        <View style={styles.totalRow}>
                            <Text style={styles.totalAmount}>Total:</Text>
                            <Text style={styles.totalAmount}>
                                {bookingDataList.reduce(
                                    (total, order) => total + order.eventClass.price,
                                    //  + order.eventClass.vat
                                    0
                                )}
                            </Text>
                        </View>
                    </View>
                </View>

                {/* Footer */}
                <Text style={styles.notes}>{t("thankMsgInvoice")}</Text>
            </Page>
        </Document>
        // </PDFViewer>
    );
};

export default EqPdfInvoice;
