import { EqCurrency } from "../../enums";

export interface IEqPublicEventFinancialDto {
    // Class order ID (order of joined attendee)
    id: string;
  
    eventClass: EqFinancialEventClassInfo;
  
    event: EqFinancialEventInfo;
  
    // Attendee information
    user: EqFinancialUserInfo;
  
    // Payment date (epoch time)
    paymentDate: number;
  
    // Order status
    slotStatus:EventClassOrderStatusEnum;
  }
  
  export interface EqFinancialEventClassInfo {
    title: string;
    description: string;
    capacity: number;
    price: number;
  }
  
  export interface EqFinancialEventInfo {
    title: string;
    currency?: EqCurrency; // Nullable enum
    startDate: number; // Epoch time
  }
  
  export interface EqFinancialUserInfo {
    firstName?: string; // Nullable string
    lastName?: string; // Nullable string
    email: string;
  }

  // Enums (You should replace these with actual enum definitions if available)
export enum EventClassOrderStatusEnum {
   
    Pending,
    Paid,
    OwnerCancelled,
    AttendeeCancelled
  }