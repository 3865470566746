import React, { useEffect, useState } from "react";
import "./EqFinancialPublicEvent.scss";
import { Row, Col } from "antd";
import { Checkbox } from "antd";
import EqCollapse, { IEqCollapse } from "../../custom/eqCollapse/EqCollapse";
import { paymentStatusType, eqNotificationTypes } from "../../shared/enums";
import moment from "moment";
import { ApiEndpoints, FrontEndPoints, openNotificationWithIcon } from "../../shared/Common";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import {
    EventClassOrderStatusEnum,
    IEqPublicEventFinancialDto,
} from "../../shared/interfaces/Financials/IEqPublicEventFinancialDto";

export interface IEqFinancialDetail extends IEqPublicEventFinancialDto {
    selected: boolean;
    display: boolean;
}
export interface IEqFinancial {
    financial: IEqFinancialDetail;
}
const EqFinancialPublicEvent = ({ financial }: IEqFinancial) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [check, setCheck] = useState(financial.selected);
    useEffect(() => {
        checkChange(financial.selected);
        console.log(financial);
    }, [financial]);
    function checkChange(selected: boolean) {
        setCheck(selected);
    }
    function onChange() {
        setCheck(!check);
    }
    const messageClicked = () => {
        var out1 = `${FrontEndPoints.trainer.composeMessage}?sender=${financial.user.email}`;
        navigate(out1);
    };
    const invoiceClicked = (id: any) => {
        // setLoading(false);
        Api.post(ApiEndpoints.InvoiceRequest.TrainerInvoice, { id: id }).then(
            (response) => {
                if (response && response?.status === 200) {
                    let result = response.data;
                    // setFormData(result);
                    openNotificationWithIcon(
                        "Success",
                        "Invoice generated.",
                        eqNotificationTypes.success
                    );
                } else {
                    openNotificationWithIcon(
                        "Error",
                        "Unable to Create Invoice, Contact Administrator",
                        // error?.response?.data,
                        eqNotificationTypes.error
                    );
                }
            },
            (error: any) => {
                // setLoading(false);
                openNotificationWithIcon(
                    "Error",
                    "Unable to Create Invoice, Contact Administrator",
                    // error?.response?.data,
                    eqNotificationTypes.error
                );
            }
        );
    };

    const getClass = (param: number) => {
        switch (param) {
            case 0:
                return "purple";
            case 1:
                return "green";
            case 2:
                return "red";
            case 3:
                return "orange";
            default:
                return "orange";
        }
    };
    const CollapseProps: IEqCollapse = {
        display: financial.display,
        header: [
            <Row justify="start" align="middle">
                <Col className="" span={2}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="" flex="auto">
                    {financial.user?.firstName} {financial.user?.lastName}
                </Col>
            </Row>,
        ],
    };
    return (
        <>
            <Row className="eq-f-row hidden-sm">
                <Col className="eq-f-td" span={1}>
                    <Checkbox onChange={onChange} checked={check}></Checkbox>
                </Col>
                <Col className="eq-f-td" span={3}>
                    {financial.user?.firstName} {financial.user?.lastName}
                </Col>
                <Col className="eq-f-td" span={3}>
                    {/* {EqDisciplineType[financial.event.disciplineType]} */}
                    {financial.event.title}
                </Col>
                <Col className="eq-f-td" span={4}>
                    {moment(financial.paymentDate).format("DD MMMM, YYYY HH:mm")}
                </Col>
                {/* {financial.event.accessType == EqEventAccessType.Public && (

          <Col className="eq-f-td" span={3}>
            {financial.event.duration} Minutes
          </Col>
        )} */}
                <Col className="eq-f-td" span={3}>
                    {/* <span className="eq-f-currency">{financial.event.currency} </span> */}
                    {financial.eventClass.price}
                </Col>
                {/* <Col className="eq-f-td" span={2}>
          <span className="eq-f-currency">Kr. </span>
          {"1.23"}
        </Col> */}
                <Col className="eq-f-td" span={3}>
                    <span className={`eq-status-${getClass(financial.slotStatus)}`}>
                        {EventClassOrderStatusEnum[financial.slotStatus]}
                    </span>
                </Col>

                <Col className="eq-f-td" span={2}>
                    <div className="">
                        {/* <span className="eq-action-invite" onClick={messageClicked}>
              <img alt="" src="/Invite.svg" /> 
            </span> */}
                        <span
                            className=""
                            // onClick={() => invoiceClicked(financial.id)}
                        >
                            <img alt="" src="/Envelop.svg" /> Invoice
                        </span>
                        {/*<span className="eq-action-delete"><img alt="" src="/Delete.svg" /></span>*/}
                    </div>
                </Col>
            </Row>

            <div className="hidden-md">
                <EqCollapse {...CollapseProps}>
                    <>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("event")}
                            </Col>
                            <Col className="" span={24}>
                                {/* {EqDisciplineType[financial.event.disciplineType]} */}
                                {financial.event.title}
                                {/* {financial.event} */}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("paymentDate")}
                            </Col>
                            <Col className="" span={24}>
                                {moment(financial.paymentDate).format("DD MMMM, YYYY HH:mm")}
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        {/* {financial.event.accessType == EqEventAccessType.Public && (
              <Row className="eq-card-row">
                <Col className="eq-card-col-heading" span={24}>
                  Duration
                </Col>
                <Col className="" span={24}>
                  {financial.event.duration}
                </Col>
              </Row>
            )} */}
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("amount")}
                            </Col>
                            <Col className="" span={24}>
                                Kr.{" "}
                                <span className="eq-f-currency">{financial.eventClass.price}</span>
                            </Col>
                        </Row>
                        {/* <Row className="eq-card-row">
              <Col className="eq-card-col-heading" span={24}>
                Fee
              </Col>
              <Col className="" span={24}>
                Kr. <span className="eq-f-currency">{"1.23"}</span>
              </Col>
            </Row> */}
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("status")}
                            </Col>
                            <Col className="" span={24}>
                                <span className={`eq-status-${getClass(financial.slotStatus)}`}>
                                    {EventClassOrderStatusEnum[financial.slotStatus]}
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex="auto" className="eq-card-divider"></Col>
                        </Row>
                        <Row className="eq-card-row">
                            <Col className="eq-card-col-heading" span={24}>
                                {t("actions")}
                            </Col>
                            <Col className="" span={24}>
                                <div className="">
                                    <span className="eq-action-message" onClick={messageClicked}>
                                        <img alt="" src="/Invite.svg" />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </>
                </EqCollapse>
            </div>
        </>
    );
};
export default EqFinancialPublicEvent;
