import React, { useEffect, useState } from "react";
import { Collapse, Checkbox, Radio, Button, Row, Col, Card } from "antd-v5"; // Import Radio from Ant Design
import "./EqJoinSingleEvent.scss"; // Import the SASS file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faClipboard } from "@fortawesome/free-solid-svg-icons";

import { EqCurrency } from "../../../components/shared/enums";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { EqInput } from "../../../components/custom/eqInput/EqInput";
import EqButton from "../../../components/custom/eqButton/EqButton";
import { useAuthState } from "../../../components/context/Context";
import { openNotificationWithIcon, ValidateIsUser } from "../../../components/shared/Common";
const { Panel } = Collapse;

interface EqJoinSingleEventProps {
    attendUsingName: any;
    selectedClasses: { [key: string]: string | string[] }; // Key: Alternative ID, Value: Selected Class ID(s)
    eventData: any; // Structure containing alternatives and their details
    handleBuyRequest: (answers: any) => void; // Pass the answers when clicking "Next" button
    handleBackClick: () => void; // Function to handle the Back button click
}

const EqJoinSingleEvent: React.FC<EqJoinSingleEventProps> = ({
    attendUsingName,
    selectedClasses,
    eventData,
    handleBuyRequest,
    handleBackClick,
}) => {
    const { t } = useTranslation();
    const options = { year: "numeric", month: "long", day: "2-digit" };
    const userDetails: any = useAuthState();
    const isLoggedInUser: boolean = ValidateIsUser(userDetails);
    // State to store answers
    const [answers, setAnswers] = useState<{ [classId: string]: { [questionId: string]: any } }>(
        {}
    );

    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [countryCode, setCountryCode] = React.useState("+1"); // set the initial country code
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber:""
    });

    useEffect(() => {
        if (selectedClasses) {
            console.log(selectedClasses);
        }
    }, [selectedClasses]);

    // Handle text input changes
    const handleTextInputChange = (classId: string, questionId: string, value: string) => {
        setAnswers((prevState) => ({
            ...prevState,
            [classId]: {
                ...prevState[classId],
                [questionId]: value,
            },
        }));
    };
    const validateFormData = (data: any): boolean => {
        let isComplete = true;
        const msg = t("incompleteData");
        const desc = t("isEmpty");
    
        if (data.firstName === "") {
            openNotificationWithIcon(msg,  t("firstName") + desc);
            isComplete = false;
          } else if (data.lastName === "") {
            openNotificationWithIcon(msg, t("lastName") + desc);
            isComplete = false;
          } else if (data.studentEmail === "") {
            openNotificationWithIcon(msg,  t("email") + desc);
            isComplete = false;
          }
          else if (data.phoneNumber === "") {
            openNotificationWithIcon(msg,  t("phoneNumber") + desc);
            isComplete = false;
          }
          return isComplete;
    }
    
    // Handle checkbox changes for multi-option questions
    const handleCheckboxChange = (
        classId: string,
        questionId: string,
        optionId: string,
        checked: boolean
    ) => {
        setAnswers((prevState) => {
            const existingAnswers = prevState[classId]?.[questionId] || [];
            const updatedAnswers = checked
                ? [...existingAnswers, optionId]
                : existingAnswers.filter((id: string) => id !== optionId);

            return {
                ...prevState,
                [classId]: {
                    ...prevState[classId],
                    [questionId]: updatedAnswers,
                },
            };
        });
    };

    // Handle radio button changes
    const handleRadioChange = (classId: string, questionId: string, value: string) => {
        setAnswers((prevState) => ({
            ...prevState,
            [classId]: {
                ...prevState[classId],
                [questionId]: value,
            },
        }));
    };

    const handleSubmit = () => {
        // Prepare request data for the API
        debugger;



        const requestData:any = {
            attendUsingName: attendUsingName, // Replace this with the actual name input by the user
            selectedClasses: eventData.alternatives
                .map((alternative: any) => {
                    const selectedClass = selectedClasses[alternative.id];

                    // If selectedClass is null or undefined, skip this alternative
                    if (!selectedClass) return null;

                    // Ensure we handle both single selected class ID and array of class IDs
                    const selectedClassIds = Array.isArray(selectedClass)
                        ? selectedClass
                        : [selectedClass];

                    // For each selected class, gather answers
                    return selectedClassIds.map((classId: string) => {
                        const answers = alternative.questions.map((question: any) => {
                            if (question.questionType === 0) {
                                // For free text input questions
                                return {
                                    questionId: question.id,
                                    answerText: getAnswerForQuestion(classId, question.id), // Get the user's answer for the question
                                    selectedOptions: [], // No options for text input
                                };
                            } else {
                                // For multiple/single choice questions
                                return {
                                    questionId: question.id,
                                    answerText: "", // No free text input for this question
                                    selectedOptions: getSelectedOptionIds(classId, question.id), // Get selected option IDs for this question
                                };
                            }
                        });

                        return {
                            classId: classId, // Use the selected class ID
                            answers: answers, // List of answers for this class
                        };
                    });
                })
                .flat()
                .filter((classData: any) => classData !== null), // Flatten the array and filter out null values
        };

        if(!isLoggedInUser){
            const isValid = validateFormData(formData);
            if (!isValid) {
                return; // Stop execution if validation fails
            }
            requestData.firstName=formData.firstName;
            requestData.lastName=formData.lastName;
            requestData.email=formData.email;
            requestData.phoneNumber=formData.phoneNumber;

            
        }
        handleBuyRequest(requestData);
        console.log(requestData); // Log or send this requestData to the API

    };

    // Helper function to retrieve free text answers for a specific class and question
    const getAnswerForQuestion = (classId: string, questionId: string) => {
        return answers[classId]?.[questionId] || ""; // Get the answer for this class and question or return an empty string
    };

    // Helper function to retrieve selected option IDs for a specific class and question
    const getSelectedOptionIds = (classId: string, questionId: string) => {
        const answer = answers[classId]?.[questionId];

        if (typeof answer === "string") {
            return [answer]; // If the answer is a string, return it as an array with a single element
        }

        return answer || []; // If it's already an array, return it, or return an empty array if undefined
    };

    // const allAlternativeIds =
    //     eventData?.alternatives.map((alternative: any) => alternative.id) || [];

        const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            const onlyNum = value.replace(/[^0-9]/g, ""); // allow only numbers
        
            // set the formatted phone number as the input value
            e.target.value = onlyNum;
            setFormData({ ...formData, phoneNumber: onlyNum });
        
         
          };
        
    return (
        <div className="eq-join-single-event">
            <Row justify={"center"}>
                <h2>{t("fillDetails")}</h2>
            </Row>
            {eventData?.alternatives.map((alternative: any, altIdx: any) => {
                const selectedClass = selectedClasses[alternative.id];
                if (!selectedClass) return null; // Skip if no selected class for this alternative

                return (
                    // <Collapse activeKey={allAlternativeIds} className="alternative-collapse" expandIconPosition="end" key={alternative.id}>
                    // <Panel  header={`Alternative: ${altIdx + 1}`} key={alternative.id}>
                    <>
                        {alternative.classList
                            .filter((classItem: any) => {
                                if (Array.isArray(selectedClass)) {
                                    return selectedClass.includes(classItem.id);
                                } else {
                                    return selectedClass === classItem.id;
                                }
                            })
                            .map((classItem: any) => (
                                <Collapse
                                    className="class-collapse"
                                    key={classItem.id}
                                    activeKey={[classItem.id]} // Keeps the collapse always open
                                    expandIcon={() => null} // Hides the default collapse icon
                                    expandIconPosition="end"
                                >
                                    <Panel
                                        header={
                                            <Row justify={"space-between"}>
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            {" "}
                                                            <FontAwesomeIcon
                                                                className="icon-clip"
                                                                icon={faClipboard}
                                                                style={{ color: "#FF8838" }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <Row>{classItem.title}</Row>
                                                            <Row>
                                                                <span>
                                                                    {t("price") + ": "}{" "}
                                                                    {classItem.price}{" "}
                                                                    {EqCurrency[eventData.currency]}
                                                                </span>
                                                                {/* <span>
                                                                    <FontAwesomeIcon
                                                                        className="icon"
                                                                        icon={faCircle}
                                                                    />
                                                                    {t("title") + ": "}{" "}
                                                                    {classItem.title}
                                                                </span> */}
                                                                <span>
                                                                    <FontAwesomeIcon
                                                                        className="icon"
                                                                        icon={faCircle}
                                                                    />
                                                                    {t("availableSpots") + ": "}{" "}
                                                                    {classItem.capacity}
                                                                </span>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    {t("heldOn")}{" "}
                                                    <span className="time">
                                                        {new Date(
                                                            eventData?.startDate
                                                        ).toLocaleDateString(
                                                            t("dateLang"),
                                                            options as any
                                                        )}
                                                    </span>{" "}
                                                    {t("at")}{" "}
                                                    <span className="time">
                                                        {moment(eventData?.startDate).format(
                                                            "HH:mm"
                                                        )}
                                                    </span>
                                                </Col>
                                            </Row>
                                        }
                                        key={classItem.id}
                                    >
                                        {/* Display questions inside Ant Design's Card component */}
                                        {alternative.questions.map((question: any) => (
                                            <Card
                                                key={question.id}
                                                title={question.questionText}
                                                className="question-card"
                                            >
                                                {question.questionType === 0 ? (
                                                    <EqInput
                                                        classNames="eq-card-input"
                                                        type="text"
                                                        placeholder="Your answer"
                                                        onChange={(e: any) =>
                                                            handleTextInputChange(
                                                                classItem.id,
                                                                question.id,
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <div>
                                                        {question.isMultiOptionAllowed ? (
                                                            question.options.map((option: any) => (
                                                                <Row key={option.id}>
                                                                    <Checkbox
                                                                        onChange={(e) =>
                                                                            handleCheckboxChange(
                                                                                classItem.id,
                                                                                question.id,
                                                                                option.id,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    >
                                                                        {option.optionText}
                                                                    </Checkbox>
                                                                </Row>
                                                            ))
                                                        ) : (
                                                            <Radio.Group
                                                                onChange={(e) =>
                                                                    handleRadioChange(
                                                                        classItem.id,
                                                                        question.id,
                                                                        e.target.value
                                                                    )
                                                                }
                                                            >
                                                                {question.options.map(
                                                                    (option: any) => (
                                                                        <Row key={option.id}>
                                                                            <Radio
                                                                                value={option.id}
                                                                            >
                                                                                {option.optionText}
                                                                            </Radio>
                                                                        </Row>
                                                                    )
                                                                )}
                                                            </Radio.Group>
                                                        )}
                                                    </div>
                                                )}
                                            </Card>
                                        ))}
                                    </Panel>
                                </Collapse>
                            ))}
                    </>
                    // </Panel>
                    // </Collapse>
                );
            })}

            {!isLoggedInUser && (
                <Row className="register-event-gust">
                    <Row justify={"center"} className="section-heading">{t("registrationForm")}</Row>
                    <Row justify={"center"} className="section-sub-heading">{t("registrationFormSub")}</Row>

                    <Col className="col-1" xs={24} sm={24} md={12}>
                        <div className="eq-input-group">
                            <div className="input-label">{t("firstName")}*</div>
                            <EqInput
                                className="name-input"
                                value={formData.firstName}
                                onChange={(e: any) =>
                                    setFormData({ ...formData, firstName: e.target.value })
                                }
                            />
                        </div>
                        <div className="eq-input-group">
                            <div className="input-label">{t("lastName")}*</div>
                            <EqInput
                                className="name-input"
                                value={formData.lastName}
                                onChange={(e: any) =>
                                    setFormData({ ...formData, lastName: e.target.value })
                                }
                            />
                        </div>
                        <div className="eq-input-group">
                            <div className="input-label">{t("e-mail")}*</div>
                            <EqInput
                                className="name-input"
                                type="email"
                                value={formData.email}
                                onChange={(e: any) =>
                                    setFormData({ ...formData, email: e.target.value })
                                }
                            />
                        </div>

                        <div className="eq-input-group">
                        <div className="event-type">
                          <div className="input-label">{t("phoneNumber")}*</div>
                          <div className="toggler">
                            <EqInput
                              type="text"
                              value={formData.phoneNumber}
                            //   addonBefore={
                            //     <>
                            //       {/* <Select options={options}></Select> */}
                            //       <Select
                            //         value={countryCode}
                            //         onChange={handleCountryCodeChange}
                            //         bordered={false}
                            //       >
                            //         <Option value="+47">
                            //           <span role="img" aria-label="Norway">
                            //             🇳🇴
                            //           </span>{" "}
                            //           +47
                            //         </Option>
                            //         <Option value="+46">
                            //           <span role="img" aria-label="Sweden">
                            //             🇸🇪
                            //           </span>{" "}
                            //           +46
                            //         </Option>
                            //         <Option value="+1">
                            //           <span role="img" aria-label="USA">
                            //             🇺🇸
                            //           </span>{" "}
                            //           +1
                            //         </Option>
                            //         <Option value="+45">
                            //           <span role="img" aria-label="Denmark">
                            //             🇩🇰
                            //           </span>{" "}
                            //           +45
                            //         </Option>
                            //       </Select>
                            //     </>
                            //   }
                              placeholder="Enter your phone number"
                              onChange={handlePhoneNumberChange}
                             
                            />
                          </div>
                        </div>
                      </div>

                        <Row justify={"center"}>
                            {" "}
                            <EqButton   onClick={handleSubmit}>{t("buy")}</EqButton>
                        </Row>
                    </Col>
                </Row>
            )}

            <Row justify={"end"} gutter={[8, 8]} style={{ marginTop: "20px" }}>
                <Col>
                    {" "}
                    <EqButton bordered onClick={handleBackClick}>
                        {t("back")}
                    </EqButton>
                </Col>
             
                {isLoggedInUser &&   <Col>
                    <EqButton onClick={handleSubmit}>{t("buy")}</EqButton>
                </Col>}
            </Row>
        </div>
    );
};

export default EqJoinSingleEvent;
