import React, { useEffect, useState } from "react";
import "./EqPublicEventCard.scss";
import { Card, Col, Row, Skeleton, Button, Badge } from "antd-v5";
import moment from "moment";
import Meta from "antd/lib/card/Meta";
import { useTranslation } from "react-i18next";
import AsyncImage from "../../components/custom/asyncImage/AsyncImage";
import { EqDisciplineType } from "../../components/shared/enums";
import Title from "antd-v5/es/typography/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faCircle } from "@fortawesome/free-solid-svg-icons";
import EqButton from "../../components/custom/eqButton/EqButton";
import EqTags from "../../components/custom/eqTags/EqTags";

export interface IEqPublicEventCard {
    eventData: any;
    onCardClick?: () => void; // Callback for card click
    onEdit?: () => void; // Callback for edit button
    onDelete?: () => void; // Callback for delete button
    onDuplicate?: () => void; // Callback for delete button
}

const EqPublicEventCard = ({
    eventData,
    onCardClick,
    onEdit,
    onDuplicate,
    onDelete,
}: IEqPublicEventCard) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const options = { year: "numeric", month: "long", day: "2-digit" };

    useEffect(() => {
        // Set loading to false after 1 second
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, [eventData]);

    // Placeholder for event image alt text and source
    const imgSrc = eventData?.image?.url || "placeholder.jpg";
    const alt = eventData?.title || "Event Image";

    // Calculate days difference between event start date and current date
    const calculateDaysDifference = () => {
        const today = new Date();
        const eventStartDate = new Date(eventData?.startDate);
        // Calculate the time difference in milliseconds
        const timeDiff = eventStartDate.getTime() - today.getTime();
        // Convert milliseconds to days
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return daysDiff > 0 ? ` ${t("in")} ${daysDiff} ${t("days")} ` : t("eventStarted");
    };

    return (
        <div className="eq-public-event-card">
            {/* <Badge.Ribbon   text="is">
              </Badge.Ribbon> */}
            <Card onClick={onCardClick} hoverable style={{ width: "100%" }}>
                <Meta
                    description={
                        loading ? (
                            <>
                                <div>
                                    <Skeleton.Image
                                        active
                                        style={{
                                            maxWidth: "100%",
                                            width: "400px",
                                            height: "250px",
                                        }}
                                    />
                                </div>
                                <div className="skeleton-type">
                                    <Skeleton.Button
                                        active
                                        size="small"
                                        shape="round"
                                        style={{ maxWidth: "94px", height: "20px" }}
                                    />
                                    <Skeleton.Button
                                        active
                                        size="small"
                                        shape="round"
                                        style={{ maxWidth: "94px", height: "20px" }}
                                    />
                                </div>
                                <Skeleton active style={{ maxWidth: "100%", marginTop: "5px" }} />
                            </>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        <AsyncImage className="event-img" alt={alt} src={imgSrc} />
                                    </Col>
                                </Row>

                                <Row className="event-detail">
                                    <Col className="event-type">
                                        {t(EqDisciplineType[eventData?.disciplineType])}
                                    </Col>
                                    <Col>
                                        {eventData.isDraft ? <EqTags text={t("draft")} /> : ""}
                                    </Col>
                                </Row>

                                <Row>
                                    <Title level={3} className="eq-create-event-title">
                                        {eventData?.title}
                                    </Title>
                                </Row>
                                <Row className="event-description">
                                    <Col>
                                        <div
                                            className="description-text"
                                            dangerouslySetInnerHTML={{
                                                __html: eventData?.description || "",
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row className="event-time">
                                    <FontAwesomeIcon className="icon" icon={faCircle} /> {t("date")}{" "}
                                    :
                                    <span className="time">
                                        {new Date(eventData?.startDate).toLocaleDateString(
                                            t("dateLang"),
                                            options as any
                                        )}
                                    </span>
                                    <FontAwesomeIcon icon={faCircle} className="icon" /> {t("time")}{" "}
                                    :{" "}
                                    <span className="time">
                                        {moment(eventData?.startDate).format("hh:mm A")}
                                    </span>
                                </Row>
                                <Row className="in-days">
                                    {calculateDaysDifference()}{" "}
                                    <FontAwesomeIcon className="icon" icon={faArrowRightLong} />
                                </Row>
                            </>
                        )
                    }
                />
                {/* Edit and Delete Buttons */}
                <Row justify="end" style={{ marginTop: "10px" }}>
                    {onDuplicate && (
                        <Col>
                            <EqButton
                                type="primary"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    onDuplicate();
                                }}
                                style={{ marginRight: "8px" }}
                            >
                                {t("duplicate")}
                            </EqButton>
                        </Col>
                    )}
                    {onEdit && (
                        <Col>
                            <EqButton
                                bordered
                                type="primary"
                                
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    onEdit();
                                    }} 
                                style={{ marginRight: "8px" }}
                            >
                                {t("edit")}
                            </EqButton>
                        </Col>
                    )}
                    {onDelete && (
                        <Col>
                            {/* <EqButton color="danger" onClick={onDelete}>
                                {t("Delete")}
                                
                            </EqButton> */}
                            <span style={{ margin: "3px" }}>
                                <img alt="" src="/trash-alt-red.svg" 
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    onDelete();
                                    }} 
                                // onClick={onDelete} 
                                />
                            </span>
                        </Col>
                    )}
                </Row>
            </Card>
        </div>
    );
};

export default EqPublicEventCard;
