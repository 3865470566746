import React, { useState, useEffect } from "react";
import "./EqFinancialsBoard.scss";
import EqFinancialsList from "../eqFinancialsList/EqFinancialsList";
import { IEqFinancialDetail } from "../eqFinancial/EqFinancial";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { Row, Col, DatePicker, Button, Tabs, Modal, Input } from "antd";
import Api from "../../shared/Api";
import { useTranslation } from 'react-i18next';

import {
  ApiEndpoints,
  GenericMessage,
} from "../../shared/Common";
import { ISelectProps } from "../../shared/Interfaces";
import {
  paymentStatusType,
  EqEventAccessType,
} from "../../shared/enums";
import moment from "moment";
import EqFinancialPdfView from "../../custom/eqFinancialPdfView/EqFinancialPdfView";
import { PDFViewer } from "@react-pdf/renderer";
import TabPane from "antd/lib/tabs/TabPane";
import EqProductFinancialsList from "./eqProductFinancialsList/EqProductFinancialsList";
import EqButton from "../../custom/eqButton/EqButton";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";
import EqFinancialsListPublicEvent from "../eqFinancialsListPublicEvent/EqFinancialsListPublicEvent";

const EqFinancialsBoard = () => {
  const { Search } = Input;
  const [isPDFVisible, setIsPDFVisible] = useState(false);
  const [messageString, setMessageString] = useState("");
  // const [financials, setFinancials] = useState<IEqFinancialDetail[]>([]);
  const [financials, setFinancials] = useState<any[]>([]);
  const [financialList, setFinancialList] = useState([] as any);
  const { t, i18n } = useTranslation();
  const [filterBox, setFilterBox] = useState(true);

  const [fromDate, setFromDate] = useState<moment.Moment | null>(null);
  const [toDate, setToDate] = useState<moment.Moment | null>(null);
  const [selectedPaymentStatuses, setSelectedPaymentStatuses] = React.useState([] as any);
  const [paymentStatusOptions, setPaymentStatusOptions] = React.useState([] as any);

  const [selectedProducts, setSelectedProducts] = React.useState([] as any);
  const [productOptions, setProductOptions] = React.useState([] as any);



  const [tabKey, setTabKey] = React.useState("1");
  const [productFinancialList, setProductFinancialList] = useState([] as any);
  const [trainingFinancialList, setTrainingFinancialList] = useState([] as any);
  const [EventFinancialList, setEventFinancialList] = useState([] as any);
  const [isModalVisible, setIsModalVisible] = useState(false);


  // filter
  const [studentsName, setStudentsName] = React.useState([] as any)
  const [eventTitle, setEventTitle] = React.useState([] as any)

  const [searchStr, setSearchStr] = useState("");

  const [search, setSearch] = React.useState({
    searchVal: "",
  });


  useEffect(() => {
    GetOwnerEventFinancials();
    GetAllActivityFinancials();
    GetAllProductFinancials();
    createPaymentStatusTypes();
  }, []);


  const GetOwnerEventFinancials = () => {
    Api.get(ApiEndpoints.Financial.GetOwnerEventFinancials)
      .then((response) => {
        if (response && response.status === 200) {
          const data = response.data ? [...response.data] : [];
      
          data.forEach((evt) => {
            evt.selected = false;
            evt.display = false;
          });
          setEventFinancialList(data);
     

        } else {
          setMessageString(GenericMessage(response.status));
        }
      })
      .catch((error) => {
        setMessageString(GenericMessage(error.response.status));
      });
  };

  const GetAllActivityFinancials = () => {
    Api.get(ApiEndpoints.Financial.GetOwnerFinancials)
      .then((response) => {
        if (response && response.status === 200) {
          const data = response.data ? [...response.data] : [];
          const trainings = data?.filter(
            (opt: any) => opt.event.accessType === EqEventAccessType.Private
          );
          trainings.forEach((training) => {
            training.selected = false;
            training.display = false;
          });

          const events = data?.filter(
            (opt: any) => opt.event.accessType === EqEventAccessType.Public
          );
          events.forEach((event) => {
            event.selected = false;
            event.display = false;
          });

          setTrainingFinancialList(trainings);
          // setEventFinancialList(events);
          setFinancialList(data);
          setFinancials(data);
        } else {
          setMessageString(GenericMessage(response.status));
        }
      })
      .catch((error) => {
        setMessageString(GenericMessage(error.response.status));
      });
  };

  const GetAllProductFinancials = () => {
    Api.get(ApiEndpoints.Financial.GetOwnerProductFinancials)
      .then((response) => {
        if (response && response.status === 200) {
          const data = response.data ? [...response.data] : [];
          data.forEach((item) => {
            item.selected = false;
            item.display = false;
          });
          setProductFinancialList(data);
          createProductList(data);
        } else {
          setMessageString(GenericMessage(response.status));
        }
      })
      .catch((error) => {
        setMessageString(GenericMessage(error.response.status));
      });
  };

  const filterFinancialsByDate = (financialList: any[]) => {

    let filteredList = financialList;
    //date
    if (fromDate && toDate) {
      const startOfFromDate = moment(fromDate).startOf("day");
      const endOfToDate = moment(toDate).endOf("day");
      filteredList = filteredList.filter((item) => {
        let paymentDate= moment();
        if(item.payment)
        paymentDate = moment(item.payment.paymentDate);
        else
        paymentDate = moment(item.paymentDate);

        return paymentDate.isBetween(startOfFromDate, endOfToDate);
      });
    }

    //status
    if (selectedPaymentStatuses.length > 0) {
      filteredList = filteredList.filter(
        (item) =>
          selectedPaymentStatuses.includes(item.slotStatus + "") ||
          selectedPaymentStatuses.includes(item.status + "")
      );
    }

    //Product 
    if (selectedProducts && selectedProducts.length > 0) {
      filteredList = filteredList.filter((item) => {

        return selectedProducts.includes(item?.product?.title + "")

      }
      );
    }

    //name or email
    if (studentsName && studentsName.length > 0) {
      const searchName = studentsName.toLowerCase();
      filteredList = filteredList.filter((item) => {
        const studentName = item?.user?.firstName;
        const lastName = item?.user?.lastName;
        const username = item?.user?.email;
        //Product
        const firstName_Pro = item?.student?.firstName;
        const lastName_Pro = item?.student?.lastName;
        const username_Pro = item?.student?.email;
        return (
          (studentName && (studentName + " " + lastName).toLowerCase().includes(searchName)) ||
          (firstName_Pro && (firstName_Pro + " " + lastName_Pro).toLowerCase().includes(searchName)) ||

          (username && username.toLowerCase().includes(searchName)) ||
          (username_Pro && username_Pro.toLowerCase().includes(searchName))
        );
      });
    }

    //title
    if (eventTitle && eventTitle.length > 0) {
      const searchTitle = eventTitle.toLowerCase();
      filteredList = filteredList.filter((item) => {
        const eventName = item?.event?.title;
        const productName = item?.product?.title;

        return (
          (eventName && eventName.toLowerCase().includes(searchTitle)) ||
          (productName && productName.toLowerCase().includes(searchTitle))
        )
          ;
      });
    }

    console.log(filteredList)
    return filteredList;
  };

  const searchNameClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    setStudentsName(searchStr)

    const filteredFinancials = filterFinancialsByDate(financialList);
    setFinancials(filteredFinancials);
  };

  const searchEventClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    setEventTitle(searchStr)

    const filteredFinancials = filterFinancialsByDate(financialList);
    setFinancials(filteredFinancials);
  };


  // const filterStudentsByName = (searchStr: string) => {
  //   
  //   let filteredList = financialList;

  //   if (studentsName) {
  //     filteredList = filteredList.filter((item : any) => {
  //       const studentName = item.user.firstName;
  //       return studentName.includes(studentsName);
  //     });
  //   }
  //   return filteredList;
  // }

  const createPaymentStatusTypes = () => {
    const types = [];
    for (const x in paymentStatusType) {
      if (!isNaN(Number(x))) {
        types.push({
          key: x,
          value: getPaymentStatusClass(x),
          label: paymentStatusType[x],
        });
      }
    }
    setPaymentStatusOptions(types);
    console.log(setPaymentStatusOptions(types))
  };



  const getPaymentStatusClass = (status: any): string => {
    if (status == paymentStatusType.Paid) return "green";
    else if (status == paymentStatusType.UnpaidButAttending) return "red";
    else if (status == paymentStatusType.StudentCancelled) return "purple";
    else return "orange";
  };

  const statusProps: ISelectProps = {
    mode: "multiple",
    isTag: true,
    defaultSelectedValue: selectedPaymentStatuses ? selectedPaymentStatuses : [],
    options: paymentStatusOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedPaymentStatuses(value);
    },
  };

  const handleGeneratePDF = () => {
    setIsPDFVisible(true);
    setIsModalVisible(true);
  };


  const createProductList = (items: any[]) => {

    const productTitlesMap = new Map();

    items.forEach((item) => {
      const productTitle = item?.product.title;

      if (productTitle) {
        productTitlesMap.set(productTitle, {
          key: productTitle,
          value: productTitle,
          label: productTitle,
        });
      }
    });

    const uniqueProductTitles = Array.from(productTitlesMap.values());

    setProductOptions(uniqueProductTitles);
    console.log("titles", uniqueProductTitles);
  };




  const productProps: ISelectProps = {

    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("Product"),
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedProducts ? selectedProducts : [],
    options: productOptions,
    changeFunc: (value: any) => {
      console.log(value);

      setSelectedProducts(value);
    },
  };

  const clearFilters = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedPaymentStatuses([]);
    setSelectedProducts([]);
    setStudentsName([]);
    setEventTitle([]);
  };

  const onChangeTab = (key: any) => {
    console.log(key);
    clearFilters();
    setTabKey(key);
  };

  financials.map((f: IEqFinancialDetail, index: number) => {
    f.display = index == 0 ? true : false;
  });

  const icon = React.createElement(
    "span",
    { className: "eq-fb-filter" },
    React.createElement("img", { alt: "", src: "/Filter.svg" })
  );

  const showModal = () => {
    setIsModalVisible(true);
  };
  const showFilterBox = () => {
    setFilterBox(!filterBox);


  };

  // Function to handle the modal close event
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>

      <AnimationDiv>
        <div className="eq-FinancialsBoard-container">
          <Row className="eq-FinancialsBoard-row" justify="space-between">
            <Col className="eq-FinancialsBoard-col">
              <div className="eq-FinancialsBoard-heading">{t("payments")}</div>
            </Col>
            <Col>
              <Button
                type="primary"
                className="pdf-generate"
                onClick={handleGeneratePDF}
              >
                {t("generatePDF")}
              </Button>
            </Col>

          </Row>

          {/* filter */}
          {filterBox && (
            <Row>
              <Col span={24} className="filters">
                {/* <div className="filters"> */}
                <Row gutter={[16, 16]} className="filter-type">
                  <Col className="location-col" xs={24} sm={24} md={12} lg={11}>
                    <Row className="location-selector">
                      <Col xs={6} sm={5} md={6} lg={5}>
                        <label className="picker-label">{t("student")}</label>
                      </Col>
                      <Col className="location-input" flex="auto">
                        <Search
                          placeholder={t("searchNameMail")}
                          onChange={(e: any) => searchNameClick(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="radius-col" xs={24} sm={24} md={12} lg={11}>
                    <Row className="radius-selector">
                      <Col xs={6} sm={5} md={6} lg={5}>
                        <label className="picker-label">
                          {/* {tabKey === "3" ? t("Product") : t("title")} */}
                          {tabKey === "1" ? t("training") : tabKey === "2" ? t("event") : t("Product")}
                        </label>
                      </Col>
                      <Col className="radius-input" flex="auto">

                        {tabKey === "1" || tabKey === "2" ?
                          (

                            <Search
                              className="eq-filter-radius eq-input-info"
                              placeholder={t("searchByTitle")}
                              onChange={(e: any) => searchEventClick(e.target.value)}
                            />
                          ) : (


                            <EqSelect {...productProps}></EqSelect>


                          )}
                      </Col>
                    </Row>
                  </Col>

                  <Col className="date-col " xs={24} sm={24} md={12} lg={11}>
                    <Row className="date-selector  start-date-selector">
                      <Col xs={6} sm={5} md={6} lg={5} >
                        <label className="picker-label">{t("startDate")}</label>
                      </Col>
                      <Col className="date-input" flex="auto">

                        <DatePicker
                        placeholder={t("selectDate")}
                          className="eq-filter-radius eq-input-info"
                          onChange={(date) => setFromDate(date)}
                          value={fromDate && fromDate.isValid() ? fromDate : null}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col className="date-col" xs={24} sm={24} md={12} lg={11}>
                    <Row className="date-selector">
                      <Col xs={6} sm={5} md={6} lg={5}>
                        <label className="picker-label">
                          {t("endDate")}
                        </label>
                      </Col>
                      <Col className="date-input" flex="auto">
                        <DatePicker
                        placeholder={t("selectDate")}
                          className="eq-filter-radius eq-input-info"
                          onChange={(date) => setToDate(date)}
                          value={toDate && toDate.isValid() ? toDate : null}
                        />
                      </Col>
                    </Row>
                  </Col>


                  <Col className="search-col" xs={24} sm={24} md={12} lg={11}>
                    <div className="search">
                      <EqButton classNames="eq-input-info" type="primary" htmlType="button">
                        {t("search")}
                      </EqButton>
                    </div>
                  </Col>
                </Row >

              </Col >
            </Row >

          )}


          <Tabs defaultActiveKey="1" onChange={onChangeTab}>
            <TabPane tab={t("trainings")} key="1">
              <div className="list">
                <RevealList>
                  <EqFinancialsList
                    financialsList={filterFinancialsByDate(trainingFinancialList)}
                    accessType={EqEventAccessType.Private}
                  />
                </RevealList>
              </div>
            </TabPane>
            <TabPane tab={t("events")} key="2">
              <RevealList>
                <EqFinancialsListPublicEvent
                  financialsList={filterFinancialsByDate(EventFinancialList)}
                />
              </RevealList>
            </TabPane>
            <TabPane tab={t("products")} key="3">
              <RevealList>
                <EqProductFinancialsList
                  financialsList={filterFinancialsByDate(productFinancialList)}
                />
              </RevealList>
            </TabPane>
          </Tabs>

          <Modal
            title="PDF Viewer"
            open={isModalVisible}
            onCancel={handleCancel}
            footer={null} // Hide the footer if you don't need it
            width={1050} // Adjust the width as needed
          >
            <PDFViewer className="pdf-viewer" width={1000} height={1000}>
              <EqFinancialPdfView
                financialsList={
                  tabKey === "2"
                    ? filterFinancialsByDate(EventFinancialList)
                    : tabKey === "3"
                      ? filterFinancialsByDate(productFinancialList)
                      : filterFinancialsByDate(trainingFinancialList)
                }
                type={
                  tabKey === "2"
                    ? "Event"
                    : tabKey === "1"
                      ? "Training"
                      : "Product"
                }
              />
            </PDFViewer>
          </Modal>
          {/* <div className="pdf-view">
          {isPDFVisible && (
            <PDFViewer className="pdf-viewer" width={1000} height={1000}>
              <EqFinancialPdfView
                financialsList={
                  tabKey === "2"
                    ? filterFinancialsByDate(EventFinancialList)
                    : tabKey === "3"
                    ? filterFinancialsByDate(productFinancialList)
                    : filterFinancialsByDate(trainingFinancialList)
                }
                type={
                  tabKey === "2"
                    ? "Event"
                    : tabKey === "1"
                    ? "Training"
                    : "Product"
                }
              />
            </PDFViewer>
          )}
        </div> */}
        </div >
      </AnimationDiv>

    </>
  );
};

export default EqFinancialsBoard;
